var render = function () {
  var _vm$agencyToEdit, _vm$meData;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading || _vm.loadingUpload
    }
  }, [_c('section', [_c('b-card-title', {
    staticClass: "py-1 m-0",
    staticStyle: {
      "position": "sticky",
      "top": "45px",
      "z-index": "100",
      "background": "white"
    }
  }, [_c('div', {
    staticClass: "d-flex-between gap-1"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_vm.readonly ? _c('div', [!_vm.isMobileView ? _c('feather-icon', {
    staticClass: "mx-50",
    attrs: {
      "icon": "UserIcon",
      "size": "24"
    }
  }) : _vm._e(), _c('span', {
    class: _vm.isMobileView ? 'font-medium-2 text-nowrap' : ''
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.agencyDetail')) + " ")])], 1) : _c('div', [!_vm.isMobileView ? _c('feather-icon', {
    staticClass: "mx-50",
    attrs: {
      "icon": "EditIcon",
      "size": "24"
    }
  }) : _vm._e(), _c('span', {
    class: _vm.isMobileView ? 'font-medium-2' : ''
  }, [_vm._v(" " + _vm._s(_vm.$t('agency.editAgency')) + " ")])], 1), _vm._v(" :"), _c('kbd', {
    staticClass: "text-dark ml-50"
  }, [_vm._v(_vm._s((_vm$agencyToEdit = _vm.agencyToEdit) === null || _vm$agencyToEdit === void 0 ? void 0 : _vm$agencyToEdit.agencyCode))])]), _c('div', [_vm.readonly ? [_c('div', {
    class: _vm.isMobileView ? 'd-flex flex-wrap justify-content-end' : ''
  }, [_vm.isVisibleResetBalance ? _c('b-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-edit-balance",
      modifiers: {
        "modal-edit-balance": true
      }
    }],
    staticClass: "py-50 py-md-1 mt-50 mt-md-0 mr-1",
    attrs: {
      "variant": "danger"
    }
  }, [_c('span', {}, [_vm._v(_vm._s(_vm.$t('agency.editBalance.title')))])]) : _vm._e(), _c('b-button', {
    staticClass: "py-50 py-md-1 mt-50 mt-md-0",
    class: {
      'cursor-not-allowed': !_vm.allowTopup
    },
    attrs: {
      "variant": _vm.allowTopup ? 'warning' : '',
      "disabled": !_vm.allowTopup || !(_vm.canAccess('topup.requestTopup') || ['OPE', 'SE'].includes((_vm$meData = _vm.meData) === null || _vm$meData === void 0 ? void 0 : _vm$meData.type) && _vm.isRoleF1)
    },
    on: {
      "click": _vm.handleShowModalCreateTopup
    }
  }, [_c('span', {}, [_vm._v(_vm._s(_vm.isMobileView ? 'Tạo lệnh nạp tiền' : _vm.$t('agency.topupForAgency')))])]), _vm.allowEdit ? _c('b-button', {
    staticClass: "ml-1 py-50 py-md-1",
    class: _vm.isMobileView ? 'mt-50' : '',
    attrs: {
      "variant": "info",
      "disabled": !_vm.canAccess('agency.updateAgency')
    },
    on: {
      "click": _vm.handleEdit
    }
  }, [_c('span', {}, [_vm._v(_vm._s(_vm.$t('edit')))])]) : _vm._e()], 1)] : _c('b-button', {
    staticClass: "ml-1",
    class: _vm.isMobileView ? 'p-1' : '',
    attrs: {
      "variant": "outline-info",
      "disabled": !_vm.canAccess('agency.updateAgency')
    },
    on: {
      "click": _vm.handleCancelEdit
    }
  }, [_c('span', {}, [_vm._v(_vm._s(_vm.$t('cancelEdit')))])])], 2)])]), _c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _vm$meData2;
        var handleSubmit = _ref.handleSubmit;
        return [_c('b-form', {
          staticClass: "position-relative",
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.updatedAgencyHandle);
            }
          }
        }, [_c('b-card', {
          staticClass: "border",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.agencyInfo')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelOwner'),
            "rules": "required|max:150"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "agencyOwner"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelOwner')) + " "), _vm.isEditActive ? _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")]) : _vm._e()];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "agencyOwner",
                  "disabled": _vm.readonly,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "150",
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('agency.placeholderOwner')
                },
                model: {
                  value: _vm.agencyToEdit.agencyOwner.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit.agencyOwner, "name", $$v);
                  },
                  expression: "agencyToEdit.agencyOwner.name"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelEmail'),
            "rules": "required|email|max:150",
            "mode": "eager"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "emailAddress"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelEmail')) + " "), _vm.isEditActive ? _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")]) : _vm._e()];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "emailAddress",
                  "disabled": _vm.readonly,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "debounce": "300",
                  "placeholder": _vm.$t('agency.placeholderEmail')
                },
                model: {
                  value: _vm.agencyToEdit.emailAddress,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "emailAddress", $$v);
                  },
                  expression: "agencyToEdit.emailAddress"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelOwnerPhone'),
            "rules": "required|phoneNumber"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "ownerPhone"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelOwnerPhone')) + " "), _vm.isEditActive ? _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")]) : _vm._e()];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "ownerPhone",
                  "disabled": _vm.readonly,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('agency.placeholderOwnerPhone')
                },
                model: {
                  value: _vm.agencyToEdit.agencyOwner.phoneNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit.agencyOwner, "phoneNumber", $$v);
                  },
                  expression: "agencyToEdit.agencyOwner.phoneNumber"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.birthday')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "birthday"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.birthday')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('flat-pickr', {
                staticClass: "d-none",
                model: {
                  value: _vm.agencyToEdit.birthDayOwner,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "birthDayOwner", $$v);
                  },
                  expression: "agencyToEdit.birthDayOwner"
                }
              }), _c('flat-pickr', {
                staticClass: "form-control",
                attrs: {
                  "name": "birthday",
                  "placeholder": _vm.$t('customer.placeholderDoB'),
                  "disabled": _vm.readonly,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "config": {
                    allowInput: true,
                    locale: _vm.locale === 'vi' ? _vm.Vietnamese : null,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd-m-Y',
                    altInput: true,
                    disableMobile: true,
                    maxDate: new Date(),
                    minDate: new Date(1920, 0, 1)
                  }
                },
                model: {
                  value: _vm.agencyToEdit.birthDayOwner,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "birthDayOwner", $$v);
                  },
                  expression: "agencyToEdit.birthDayOwner"
                }
              }), _c('feather-icon', {
                staticStyle: {
                  "position": "absolute",
                  "top": "55%",
                  "right": "20px",
                  "transform": "translateY(-50%)",
                  "cursor": "pointer",
                  "color": "#aaa"
                },
                attrs: {
                  "icon": "CalendarIcon",
                  "size": "20"
                }
              })], 1), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelIdentityCardNum'),
            "rules": "max:15"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "identityCardNumber"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelIdentityCardNum')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "identityCardNumber",
                  "disabled": _vm.readonly,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('agency.placeholderIdentityCardNum')
                },
                model: {
                  value: _vm.agencyToEdit.identityCardNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "identityCardNumber", $$v);
                  },
                  expression: "agencyToEdit.identityCardNumber"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelIdentityCardName'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "identityCardName"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelIdentityCardName')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "identityCardName",
                  "disabled": _vm.readonly,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "maxlength": "255",
                  "placeholder": _vm.$t('agency.placeholderIdentityCardName')
                },
                model: {
                  value: _vm.agencyToEdit.identityCardName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "identityCardName", $$v);
                  },
                  expression: "agencyToEdit.identityCardName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelPlaceOfIssue'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "placeOfIssue"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelPlaceOfIssue')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "placeOfIssue",
                  "disabled": _vm.readonly,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "maxlength": "255",
                  "placeholder": _vm.$t('agency.placeholderPlaceOfIssue')
                },
                model: {
                  value: _vm.agencyToEdit.placeOfIssue,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "placeOfIssue", $$v);
                  },
                  expression: "agencyToEdit.placeOfIssue"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          ref: "refProviderIDFrontImageFile",
          attrs: {
            "name": _vm.$t('agency.uploadFrontId')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "font-weight-bold",
                attrs: {
                  "label-for": "frontIdFrontImage"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(_vm._s(_vm.$t('agency.uploadFrontId')))])];
                  },
                  proxy: true
                }], null, true)
              }, [!_vm.readonly ? _c('div', {
                staticClass: "input-group custom-file-button"
              }, [_c('label', {
                staticClass: "input-group-text",
                attrs: {
                  "for": "frontIdFrontImage"
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.uploadImage')))]), _c('b-form-file', {
                ref: "refInputFrontIDPhoto",
                staticClass: "form-control",
                attrs: {
                  "id": "frontIdFrontImage",
                  "disabled": _vm.readonly,
                  "accept": ".jpg, .png",
                  "plain": "",
                  "placeholder": "Chưa chọn ảnh",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                on: {
                  "input": _vm.inputImageIDCardFrontFormData
                },
                model: {
                  value: _vm.agencyFile.idFrontImage,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyFile, "idFrontImage", $$v);
                  },
                  expression: "agencyFile.idFrontImage"
                }
              })], 1) : _vm._e(), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          ref: "refProviderIDBackImageFile",
          attrs: {
            "name": _vm.$t('agency.uploadBackId')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "font-weight-bold",
                attrs: {
                  "label-for": "frontIdBackImage"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.uploadBackId')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [!_vm.readonly ? _c('div', {
                staticClass: "input-group custom-file-button"
              }, [_c('label', {
                staticClass: "input-group-text",
                attrs: {
                  "for": "frontIdBackImage"
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.uploadImage')))]), _c('b-form-file', {
                ref: "refInputBackIDPhoto",
                staticClass: "form-control",
                attrs: {
                  "id": "frontIdBackImage",
                  "accept": ".jpg, .png",
                  "disabled": _vm.readonly,
                  "plain": "",
                  "placeholder": "Chưa chọn ảnh",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                on: {
                  "input": _vm.inputImageIDCardBackFormData
                },
                model: {
                  value: _vm.agencyFile.idBackImage,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyFile, "idBackImage", $$v);
                  },
                  expression: "agencyFile.idBackImage"
                }
              })], 1) : _vm._e(), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "5",
            "md": "6",
            "centered": ""
          }
        }, [_vm.agencyToEdit.identityCardFrontImage ? _c('b-img', {
          staticClass: "border shadow-lg mb-1",
          staticStyle: {
            "max-height": "200px"
          },
          attrs: {
            "src": _vm.agencyToEdit.identityCardFrontImage,
            "fluid": "",
            "alt": "id card"
          }
        }) : _c('span', {
          staticClass: "text-secondary"
        }, [_vm._v(" Chưa có ảnh ")])], 1), _c('b-col', {
          attrs: {
            "cols": "5",
            "md": "6",
            "centered": ""
          }
        }, [_vm.agencyToEdit.identityCardBackImage ? _c('b-img', {
          staticClass: "border shadow-lg mb-1",
          staticStyle: {
            "max-height": "200px"
          },
          attrs: {
            "src": _vm.agencyToEdit.identityCardBackImage,
            "fluid": "",
            "alt": "id card"
          }
        }) : _c('span', {
          staticClass: "text-secondary"
        }, [_vm._v(" Chưa có ảnh ")])], 1)], 1)], 1)], 1), _c('b-card', {
          staticClass: "border mt-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.agencyBusinessInfo')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.agencyCompanyName'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "agency-name"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.agencyCompanyName')) + " "), _vm.isEditActive ? _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")]) : _vm._e()];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "agency-name",
                  "disabled": _vm.readonly,
                  "name": _vm.$t('agency.agencyCompanyName'),
                  "placeholder": "".concat(_vm.$t('agency.enter'), " ").concat(_vm.$t('agency.agencyCompanyName')),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.agencyToEdit.agencyName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "agencyName", $$v);
                  },
                  expression: "agencyToEdit.agencyName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Tax Code",
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "tax-code"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelTaxCode')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "tax-code",
                  "disabled": _vm.readonly,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('agency.placeholderTaxCode')
                },
                model: {
                  value: _vm.agencyToEdit.taxCode,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "taxCode", $$v);
                  },
                  expression: "agencyToEdit.taxCode"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelMonthlyReport'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "enableSendMonthlyReport",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelMonthlyReport')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [!_vm.readonly ? _c('v-select', {
                attrs: {
                  "options": [{
                    label: 'Yes',
                    value: true
                  }, {
                    label: 'No',
                    value: false
                  }],
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "input-id": "enableSendMonthlyReport",
                  "placeholder": _vm.$t('agency.placeholderMonthlyReport')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyToEdit.enableSendMonthlyReport,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "enableSendMonthlyReport", $$v);
                  },
                  expression: "agencyToEdit.enableSendMonthlyReport"
                }
              }) : _c('b-form-input', {
                attrs: {
                  "value": _vm.$t([{
                    label: 'Yes',
                    value: true
                  }, {
                    label: 'No',
                    value: false
                  }].find(function (item) {
                    return item.value === _vm.agencyToEdit.enableSendMonthlyReport;
                  }).label),
                  "disabled": ""
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.agencyCode'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": _vm.$t('agency.agencyCode'),
                  "label-for": "agencyCode"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "agencyCode",
                  "value": _vm.agencyToEdit.agencyCode.toUpperCase(),
                  "disabled": _vm.readonly || true,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.upperCaseFormatter,
                  "placeholder": _vm.$t('agency.agencyCode')
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "agency-address",
            "label": _vm.$t('myAccount.addressCompany')
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "agency-address",
            "disabled": _vm.readonly,
            "name": _vm.$t('myAccount.addressCompany'),
            "placeholder": "".concat(_vm.$t('myAccount.enter'), " ").concat(_vm.$t('myAccount.addressCompany'))
          },
          model: {
            value: _vm.agencyToEdit.agencyAddress,
            callback: function callback($$v) {
              _vm.$set(_vm.agencyToEdit, "agencyAddress", $$v);
            },
            expression: "agencyToEdit.agencyAddress"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.agencyFoundingDate')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": "".concat(_vm.$t('agency.agencyFoundingDate')),
                  "label-for": "agencyFoundingDate"
                }
              }, [_c('flat-pickr', {
                staticClass: "d-none",
                model: {
                  value: _vm.agencyToEdit.agencyFoundingDate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "agencyFoundingDate", $$v);
                  },
                  expression: "agencyToEdit.agencyFoundingDate"
                }
              }), _c('flat-pickr', {
                staticClass: "form-control",
                attrs: {
                  "name": _vm.$t('agency.agencyFoundingDate'),
                  "disabled": _vm.readonly,
                  "placeholder": _vm.$t('agency.agencyFoundingDate'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "config": {
                    allowInput: true,
                    locale: _vm.locale === 'vi' ? _vm.Vietnamese : null,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd-m-Y',
                    altInput: true,
                    disableMobile: true,
                    minDate: new Date(1920, 0, 1),
                    maxDate: new Date()
                  }
                },
                model: {
                  value: _vm.agencyToEdit.agencyFoundingDate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "agencyFoundingDate", $$v);
                  },
                  expression: "agencyToEdit.agencyFoundingDate"
                }
              }), _c('feather-icon', {
                staticStyle: {
                  "position": "absolute",
                  "top": "55%",
                  "right": "20px",
                  "transform": "translateY(-50%)",
                  "cursor": "pointer",
                  "color": "#aaa"
                },
                attrs: {
                  "icon": "CalendarIcon",
                  "size": "20"
                }
              })], 1), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.paymentMethod'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "font-weight-bold",
                attrs: {
                  "label-for": "paymentMethod",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.paymentMethod')) + " "), _vm.isEditActive ? _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")]) : _vm._e()];
                  },
                  proxy: true
                }], null, true)
              }, [!_vm.readonly ? _c('v-select', {
                attrs: {
                  "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
                  "options": [{
                    label: 'overdraft',
                    value: 'OVERDRAFT'
                  }, {
                    label: 'prepay',
                    value: 'PREPAY'
                  }],
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "input-id": "paymentMethod",
                  "placeholder": _vm.$t('agency.paymentMethod')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("agency.".concat(data.label))) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("agency.".concat(data.label))) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyToEdit.paymentMethod,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "paymentMethod", $$v);
                  },
                  expression: "agencyToEdit.paymentMethod"
                }
              }) : _c('b-form-input', {
                attrs: {
                  "value": _vm.$t("agency.".concat([{
                    label: 'overdraft',
                    value: 'OVERDRAFT'
                  }, {
                    label: 'prepay',
                    value: 'PREPAY'
                  }].find(function (item) {
                    return item.value === _vm.agencyToEdit.paymentMethod;
                  }).label)),
                  "disabled": ""
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          ref: "documentContractFile",
          attrs: {
            "name": "".concat(_vm.$t('agency.documentContract'))
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "font-weight-bold",
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_vm._v(" " + _vm._s(_vm.$t('agency.documentContract')) + " ")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('div', [_vm.agencyToEdit.documentContractUrl.length ? _c('div', _vm._l(_vm.agencyToEdit.documentContractUrl.flat(), function (docContractFile, docContractFileIndex) {
                return _c('div', {
                  key: docContractFileIndex
                }, [_c('em', [_vm._v(_vm._s(docContractFileIndex + 1) + ". ")]), _c('a', {
                  staticClass: "hover-underline",
                  attrs: {
                    "href": docContractFile,
                    "alt": "download file",
                    "target": "_blank"
                  }
                }, [_vm._v(" " + _vm._s(_vm.shortenFileName(docContractFile)) + " ")]), !_vm.readonly ? _c('b-button', {
                  staticClass: "p-50 rounded-circle",
                  attrs: {
                    "variant": "flat-danger"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.deleteDocumentContract(docContractFileIndex);
                    }
                  }
                }, [_c('IAmIcon', {
                  attrs: {
                    "icon": "closeOutline"
                  }
                })], 1) : _vm._e()], 1);
              }), 0) : _c('span', {
                staticClass: "text-secondary"
              }, [_vm._v(" Chưa có file ")]), !_vm.readonly ? _c('div', [_c('div', {
                staticClass: "input-group custom-file-button"
              }, [_c('label', {
                staticClass: "input-group-text",
                attrs: {
                  "for": "documentContractUrlFile"
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.uploadContract')))]), _c('b-form-file', {
                ref: "refInputDocumentContractUrl",
                staticClass: "form-control",
                attrs: {
                  "id": "documentContractUrlFile",
                  "accept": _vm.fileCanUpload,
                  "disabled": _vm.readonly,
                  "plain": "",
                  "multiple": "",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                on: {
                  "input": _vm.handleUploadContractFiles
                },
                model: {
                  value: _vm.agencyFile.documentContract,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyFile, "documentContract", $$v);
                  },
                  expression: "agencyFile.documentContract"
                }
              })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1) : _vm._e()]), _c('small', {
                staticClass: "font-italic text-secondary"
              }, [_vm._v("Hệ thống chỉ hỗ trợ tải file " + _vm._s(_vm.fileCanUpload) + " có dung lượng nhỏ hơn " + _vm._s(_vm.sizeFormatter(_vm.MAX_SIZE_TO_UPLOAD_FILE)))])])];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.bookerId'),
            "rules": "bookerId|min:5"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": _vm.$t('agency.bookerId'),
                  "label-for": "bookerId"
                }
              }, [_c('b-form-input', {
                attrs: {
                  "id": "bookerId",
                  "disabled": _vm.readonly,
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "formatter": _vm.trimAllInputAndUpper,
                  "placeholder": _vm.$t('agency.bookerId')
                },
                model: {
                  value: _vm.agencyToEdit.bookerId,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "bookerId", $$v);
                  },
                  expression: "agencyToEdit.bookerId"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _vm.agencyToEdit.id !== 1000000 ? _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('b-form-group', {
          staticClass: "font-weight-bold",
          attrs: {
            "label-for": "agency-limit-credit",
            "label": "".concat(_vm.$t('agency.labelLimitCredit'))
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center gap-2"
        }, [_c('IAmInputMoney', {
          staticClass: "fit-content text-dark font-medium-1 font-weight-bolder",
          attrs: {
            "value-money": _vm.agencyToEdit.limitCredit,
            "disabled": ""
          },
          on: {
            "update:valueMoney": function updateValueMoney($event) {
              return _vm.$set(_vm.agencyToEdit, "limitCredit", $event);
            },
            "update:value-money": function updateValueMoney($event) {
              return _vm.$set(_vm.agencyToEdit, "limitCredit", $event);
            }
          }
        }), !(_vm.readonly && !['KTT', 'KTV'].includes((_vm$meData2 = _vm.meData) === null || _vm$meData2 === void 0 ? void 0 : _vm$meData2.type)) ? _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(0, 207, 232, 0.15)',
            expression: "'rgba(0, 207, 232, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "px-1 py-50 rounded-lg text-nowrap",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.openChangeBalanceModal(_vm.agencyToEdit);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('adjust')) + " ")]) : _vm._e()], 1)])], 1) : _vm._e()], 1)], 1)], 1), _c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.labelStatus')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelStatus'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "status",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelStatus')) + " "), _vm.isEditActive ? _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")]) : _vm._e()];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.statusAgencyOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "disabled": _vm.readonly,
                  "input-id": "status",
                  "placeholder": _vm.$t('agency.placeholderStatus')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyToEdit.status,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "status", $$v);
                  },
                  expression: "agencyToEdit.status"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1), _c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.airAccountForAgencyText')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('h5', {
          staticClass: "my-2"
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.provideAccount')) + " ")]), _c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelAirUsername'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "airAccountUsername"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelAirUsername')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "id": "airAccountUsername",
                  "options": [],
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "disabled": _vm.readonly,
                  "clearable": "",
                  "input-id": "airAccountUsername",
                  "placeholder": _vm.$t('agency.placeholderAirUsername')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyToEdit.airAccount,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "airAccount", $$v);
                  },
                  expression: "agencyToEdit.airAccount"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelAirline'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "airline",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelAirline')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.hotAirlineOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "disabled": _vm.readonly,
                  "input-id": "airline",
                  "placeholder": _vm.$t('agency.placeholderAirline')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$te(data.label) ? _vm.$t(data.label) : data.label) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyToEdit.airline,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyToEdit, "airline", $$v);
                  },
                  expression: "agencyToEdit.airline"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1), _c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-50 mb-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between w-100"
              }, [_c('h6', {
                staticClass: "text-body m-0"
              }, [_vm._v(" Mạng xã hội ")]), !_vm.readonly ? _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }, {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-primary.window",
                  modifiers: {
                    "hover": true,
                    "v-primary": true,
                    "window": true
                  }
                }],
                staticClass: "btn-icon mr-1 py-25 px-50 d-flex-center",
                attrs: {
                  "title": "Chỉnh sửa",
                  "size": "sm",
                  "variant": "outline-info"
                },
                on: {
                  "click": function click($event) {
                    return _vm.editSocialNetwork(_vm.agencyToEdit);
                  }
                }
              }, [_c('img', {
                attrs: {
                  "src": require("@icons/edit.svg")
                }
              }), !_vm.isMobileView ? _c('span', {
                staticClass: "font-medium-2 ml-25",
                staticStyle: {
                  "margin-top": "1px"
                }
              }, [_vm._v(" Thay đổi mạng xã hội ")]) : _vm._e()]) : _vm._e()], 1)];
            },
            proxy: true
          }], null, true)
        }, [_vm.agencyToEdit.socialAccounts && _vm.agencyToEdit.socialAccounts.length ? _c('div', _vm._l(_vm.agencyToEdit.socialAccounts, function (item, i) {
          return _c('div', {
            key: i,
            staticClass: "mb-50 d-flex align-items-center"
          }, [_c('span', {
            staticClass: "mr-50 font-weight-bolder",
            style: _vm.isMobileView ? "min-width: 60px" : "min-width: 120px"
          }, [_vm._v(" " + _vm._s(item.type) + " ")]), _c('b-link', {
            staticClass: "url-ellipsis",
            attrs: {
              "href": item.url,
              "target": "_blank"
            }
          }, [_vm._v(" " + _vm._s(item.url) + " ")])], 1);
        }), 0) : _c('div', [_vm._v(" Chưa thêm mạng xã hội ")])]), _vm.isRoleF1 ? _c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-50 mb-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex align-items-center justify-content-between w-100"
              }, [_c('h6', {
                staticClass: "text-body m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('reservation.note')) + " ")]), !_vm.readonly ? _c('b-button', {
                directives: [{
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: 'rgba(113, 102, 240, 0.15)',
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: {
                    "400": true
                  }
                }, {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.v-primary.window",
                  modifiers: {
                    "hover": true,
                    "v-primary": true,
                    "window": true
                  }
                }],
                staticClass: "btn-icon mr-1 py-25 px-50 d-flex-center",
                attrs: {
                  "title": "Chỉnh sửa",
                  "size": "sm",
                  "variant": "outline-info"
                },
                on: {
                  "click": function click($event) {
                    return _vm.editNote($event.target);
                  }
                }
              }, [_c('img', {
                attrs: {
                  "src": require("@icons/edit.svg")
                }
              }), !_vm.isMobileView ? _c('span', {
                staticClass: "font-medium-2 ml-25",
                staticStyle: {
                  "margin-top": "1px"
                }
              }, [_vm._v(" Sửa ghi chú ")]) : _vm._e()]) : _vm._e()], 1)];
            },
            proxy: true
          }], null, true)
        }, [_c('div', [_vm._v(" " + _vm._s(_vm.agencyToEdit.note || _vm.$t('reservation.noNote')) + " ")])]) : _vm._e(), _c('AgenciesAuditLog', {
          attrs: {
            "audit-logs": _vm.agencyToEdit.auditLogs
          }
        }), _c('div', {
          staticClass: "button_actions d-flex mt-1 justify-content-center"
        }, [_c('b-button', {
          staticClass: "py-1 px-2 px-md-3 mr-1",
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": _vm.onCancel
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('back')) + " ")]), !_vm.readonly ? _c('b-button', {
          staticClass: "py-1 px-2 px-md-3",
          attrs: {
            "variant": "info"
          },
          on: {
            "click": function click($event) {
              handleSubmit(function () {
                return _vm.updatedAgencyHandle(true);
              });
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")]) : _vm._e()], 1)], 1)];
      }
    }])
  }), _c('CreateTopupModal', {
    attrs: {
      "sender": _vm.senderData,
      "recipient": _vm.recipientData,
      "is-empty-bank-accounts": _vm.isEmptyBankAccounts,
      "topup-records": _vm.agencyToEdit.topupRecords,
      "place": _vm.placeModal
    },
    on: {
      "show": _vm.handleOpenTopupModal
    }
  }), _c('ModalEditBalance', {
    attrs: {
      "agencies-data": _vm.agenciesData,
      "current-agency-id": _vm.currentAgencyId
    },
    on: {
      "refetchAgency": _vm.refetchAgency
    }
  }), _c('AgenciesChangeBalanceModal', {
    attrs: {
      "agencies-data": _vm.agencyToEdit,
      "limit-credit": _vm.agencyToEdit.limitCredit,
      "warning-credit": _vm.agencyToEdit.warningCredit,
      "is-role-f2": _vm.isRoleF2,
      "me-data": _vm.meData,
      "agencies-data-raw": _vm.agenciesData
    },
    on: {
      "update:limitCredit": function updateLimitCredit($event) {
        return _vm.$set(_vm.agencyToEdit, "limitCredit", $event);
      },
      "update:limit-credit": function updateLimitCredit($event) {
        return _vm.$set(_vm.agencyToEdit, "limitCredit", $event);
      },
      "update:warningCredit": function updateWarningCredit($event) {
        return _vm.$set(_vm.agencyToEdit, "warningCredit", $event);
      },
      "update:warning-credit": function updateWarningCredit($event) {
        return _vm.$set(_vm.agencyToEdit, "warningCredit", $event);
      },
      "refetchAgency": _vm.refetchAgency
    }
  }), _vm.agencyToEdit ? _c('NoteModal', {
    attrs: {
      "agency-to-edit": _vm.agencyToEdit,
      "note": _vm.agencyToEdit.note,
      "agencies-data": _vm.agenciesData
    },
    on: {
      "update:note": function updateNote($event) {
        return _vm.$set(_vm.agencyToEdit, "note", $event);
      }
    }
  }) : _vm._e(), _vm.agencyToEdit ? _c('ModalAddSocialNetwork', {
    attrs: {
      "agency-to-edit": _vm.agencyToEdit,
      "social-accounts": _vm.agencyToEdit.socialAccounts,
      "agencies-data": _vm.agenciesData
    },
    on: {
      "update:socialAccounts": function updateSocialAccounts($event) {
        return _vm.$set(_vm.agencyToEdit, "socialAccounts", $event);
      },
      "update:social-accounts": function updateSocialAccounts($event) {
        return _vm.$set(_vm.agencyToEdit, "socialAccounts", $event);
      }
    }
  }) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }